import React, { cloneElement, FC } from 'react';
import { PERMISSIONS } from './permissionsMaps';
import { useGetRole } from './useGetRole';

interface hasPermissionsProps {
  permissions: any
  scopes: any
}

interface PermissionsGateProps {
  RenderError?: () => React.ReactElement,
  scopes?: any[]
  errorProps?: any
  children: React.ReactNode
}

export const hasPermissions: FC<hasPermissionsProps> = ({ permissions, scopes }) => {
  if (!scopes.length) {
    return true
  }
  
  const scopesMap: any = {}
  scopes.forEach((scope: any) => {
    scopesMap[scope] = true
  })

  return permissions.some((permission: any) => scopesMap[permission])
}

const PermissionsGate: FC<PermissionsGateProps> =
  ({
     children,
     RenderError = () => <React.Fragment></React.Fragment>,
     errorProps = null,
     scopes = []
   }) => {
    const { role } = useGetRole()
    const permissions = PERMISSIONS[role]

    const permissionGranted = hasPermissions({ permissions, scopes })

    if (!permissionGranted && !errorProps) {
      // @ts-ignore
      return <RenderError/>
    }

    if (!permissionGranted && errorProps) {
      // @ts-ignore
      return cloneElement(children, { ...errorProps });
    }

    return <>{children}</>
  };

export default PermissionsGate;