import React, { FC, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import styles from './table.module.scss';
import cn from "classnames";
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";

interface Props {
  allKeys: any[],
  currentKeys: any[],
  disabledKeys: any[],
  open: boolean,
  onClose: () => void,
  onSave: (keys: any[]) => void
}

const TableSettingsDialog: FC<Props> = ({ onClose, onSave, allKeys, disabledKeys, currentKeys, open }) => {
  const [keys, setKeys] = useState({
    currentKeys: currentKeys,
    unselectedKeys: allKeys.filter((k) => !currentKeys.find((c) => c.fieldName === k.fieldName))
  })

  const onClick = (key: any) => {
    if (keys.currentKeys.find((k) => k.fieldName === key.fieldName)) {
      setKeys(prev => ({
        currentKeys: prev.currentKeys.filter(k => k.fieldName !== key.fieldName),
        unselectedKeys: [...prev.unselectedKeys, key]
      }))
    } else {
      setKeys(prev => ({
        currentKeys: [...prev.currentKeys, key],
        unselectedKeys: prev.unselectedKeys.filter(k => k.fieldName !== key.fieldName)
      }))
    }
  }

  const onSaveClick = () => {
    onSave(keys.currentKeys)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle> Настройка таблицы </DialogTitle>

      <DialogContent>
        <div className={styles.table_settings_dialog__wrapper}>
          <div className={styles.table_settings_dialog__column}>
            <p className={styles.table_settings_dialog__column_label}> Неотображаемые поля </p>

            {keys.unselectedKeys
              .map((key) => (
                <PermissionsGate key={key.fieldName} scopes={key.permissions}>
                  <div
                    className={cn(styles.table_settings_dialog__item, styles.table_settings_dialog__item_animation)}
                    onClick={() => onClick(key)}
                  >
                    {key.label}
                  </div>
                </PermissionsGate>
              ))}
          </div>

          <div className={styles.table_settings_dialog__column}>
            <p className={styles.table_settings_dialog__column_label}> Отображаемые поля </p>

            {keys.currentKeys.map((key) => (
              <PermissionsGate key={key.fieldName} scopes={key.permissions}>
                <div
                  className={cn(styles.table_settings_dialog__item, styles.table_settings_dialog__item_animation)}
                  onClick={() => onClick(key)}
                >
                  {key.label}
                </div>
              </PermissionsGate>
            ))}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: "row-reverse" }}>
          <Button
            variant={"outlined"}
            onClick={onSaveClick}
          >
            Сохранить
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TableSettingsDialog;