import React, { FC, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styles from './table.module.scss';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import Icon from "../../../components/UI/Icons/Icon";
import cn from "classnames";
import { toast } from "react-toastify";
import TableSettingsDialog from "./TableSettingsDialog";
import { ITableSettings } from "../../../models/ITableSettings";

interface TableHeadersProps {
  onSearch: (query: any) => void
  onSorted: (key: string) => void
  onReset: () => void
  sortedParams: { [key: string]: string }
  disabledHeaders: string[]
  form: UseFormReturn
  tableSettings: ITableSettings
}

const TableHeaders: FC<TableHeadersProps> = ({
                                               onSearch,
                                               onSorted,
                                               onReset,
                                               disabledHeaders,
                                               sortedParams,
                                               form,
                                               tableSettings
                                             }) => {
  const { register, handleSubmit } = form
  const enterKey = 'Enter'
  const [openSettings, setOpenSettings] = useState(false)

  const cellSize = {
    small: styles.small_width_col,
    medium: styles.medium_width_col,
    large: styles.large_width_col
  }

  const onOpenClose = () => setOpenSettings(!openSettings)

  const onPressKeySubmit = (e: any) => {
    if (e.key === enterKey) {
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = (queryData: any) => {
    const query: any = {}

    Object.entries(queryData).forEach(([key, value]) => {
      // @ts-ignore
      if (value !== undefined || /^\s*$/.test(value)) {
        query[key] = value
      }
    })

    if (Object.keys(query).length) {
      onSearch(query)
    }
  }

  const handleSorted = (key: string) => {

    if (disabledHeaders.includes(key)) {
      toast.error(`Нельзя сортировать по этому параметру: ${key}`)
      return
    }

    onSorted(key)
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 10000,
      fontSize: theme.typography.pxToRem(18),
      border: '1px solid #dadde9',
    },
  }));

  const tooltipText = (
    <React.Fragment>
      Форматы поиска:<br/>
      1. 10-08-1999<br/>
      2. 10.08.1999<br/>
      3. 08-1999<br/>
      4. 08.1999<br/>
      5. 10-08-1999 - 10-08-2000<br/>
      6. 08-1999 - 10-1999<br/>
    </React.Fragment>
  )

  return (
    <>
      <TableSettingsDialog
        onClose={onOpenClose}
        open={openSettings}
        disabledKeys={disabledHeaders}
        allKeys={tableSettings.allKeys}
        currentKeys={tableSettings.currentKeys}
        onSave={tableSettings.onChangeKeys}
      />

      <TableRow>
        {tableSettings.currentKeys.map((header) =>
          <PermissionsGate key={header.fieldName} scopes={header.permissions}>
            <TableCell
              align={'center'}
              key={header.fieldName}
              className={cellSize[header.width]}
            >
              {header.searchable &&
                  <>
                    {header.label.includes('Дата') ?
                      <HtmlTooltip title={tooltipText} placement="top-start" className={styles.role__tooltip}>
                        <input
                          onKeyPress={onPressKeySubmit}
                          {...register(header.fieldName)}
                          style={{ width: '100%' }}
                        />
                      </HtmlTooltip>
                      :
                      <input
                        onKeyPress={onPressKeySubmit}
                        {...register(header.fieldName)}
                        style={{ width: '100%' }}
                      />
                    }
                  </>
              }
            </TableCell>
          </PermissionsGate>
        )}

        <TableCell colSpan={3} className={styles.small_width_col}>
          <div className={styles.search_icon_group__wrapper}>
            <Icon icon={'search'} onClick={handleSubmit(onSubmit)} className={styles.search_icon_group__icon}/>

            <Icon icon={'close'} onClick={onReset} className={styles.search_icon_group__icon}/>

            <Icon icon={"settings"} onClick={onOpenClose} className={styles.search_icon_group__icon}/>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        {tableSettings.currentKeys.map((header) =>
          <PermissionsGate key={header.fieldName} scopes={header.permissions}>
            <TableCell
              align={'center'}
              padding={'none'}
              className={cellSize[header.width]}
            >
              <span className={styles.header_name}>
                {header.label}
                <span>
                  {disabledHeaders.includes(header.fieldName) ?
                    <span style={{ marginRight: '2rem' }}></span>
                    :
                    <Icon
                      onClick={() => handleSorted(header.fieldName)}
                      icon={"arrowBack"}
                      className={
                        cn(
                          sortedParams[header.fieldName] === 'ASC' ? styles.icon_sorted_desc : styles.icon_sorted_asc,
                          sortedParams[header.fieldName] ? styles.icon_sorted_active : '',
                        )
                      }
                    />
                  }
                </span>
              </span>
            </TableCell>
          </PermissionsGate>
        )}

        <TableCell
          align={'center'}
          colSpan={3}
          className={styles.small_width_col}
        >
          Взаимодействие
        </TableCell>

      </TableRow>
    </>
  );
};

export default React.memo(TableHeaders);