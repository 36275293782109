import React from 'react';
import { router } from './router/router';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from 'react-query/devtools'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const routing = router()
  return (
    <div>
      {/*<DebugObserver/>*/}
      <ReactQueryDevtools initialIsOpen={false}/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {routing}
    </div>
  );
}

export default App;