import { axiosInstance } from './axios';
import { useMutation } from 'react-query';

interface useCustomMutationProps {
  method: 'post' | 'patch' | 'delete' | 'get'
  url: string
  data?: any
}

export const useCustomMutation = () =>
  useMutation(({ method, url, data }: useCustomMutationProps) =>
    axiosInstance[method](url, data))