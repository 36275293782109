import * as yup from 'yup';
import { wordEnding } from '../helpers/wordEnding';
import { FixedLengthArray } from '../models/IFixedLengthArray';

const words: FixedLengthArray<string, 3> = ['символ', 'символа', 'символов']

yup.setLocale({
  mixed: {
    default: 'Ошибка',
    notType: ({ type, }) => type === 'number' ? 'нужно число' : type === 'string' ? 'нужна строка' : type,
    required: `обязательное поле`
  },
  number: {
    integer: 'число должно быть целым',
    max: ({ max }) => `максимум ${max}`,
    min: ({ min }) => `минимум ${min}`,
    positive: 'число должно быть положительным'
  },
  string: {
    max: ({ max }) => `максимум ${wordEnding(max, words)}`,
    min: ({ min }) => `минимум ${wordEnding(min, words)}`
  }
});