import React from 'react';
import RulerMarkers from "./RulerMarkers";
import RulerWindow from "./RulerWindow";

const Ruler = () => {
  return (
    <>
     <RulerWindow/>

      <RulerMarkers/>
    </>
  );
};

export default Ruler;