import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import styles from './loader.module.scss';

const Loader = () => {
  return (
    <Box className={styles.loader_wrapper}>
      <CircularProgress color={'secondary'} />
    </Box>
  );
};

export default Loader;