import React from 'react';
import { Controller, useForm } from "react-hook-form";
import { Container, FormHelperText, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from '../sign.module.scss';
import { useCustomMutation } from "../../../api/useCustomMutation";
import { SEND_RESET_PASSWORD_LINK_URL } from "../../../api/urls";
import { toast } from "react-toastify";
import { SIGN_IN_PAGE } from "../../../router/routeConsts";
import { useNavigate } from "react-router-dom";

interface Form {
  email: string
}

const ForgotPassword = () => {
  const { handleSubmit, control, formState: { errors } } = useForm<Form>()
  const mutation = useCustomMutation()
  const navigate = useNavigate()

  const onSubmit = ({ email }: Form) => {
    mutation.mutateAsync({
      method: 'post',
      url: SEND_RESET_PASSWORD_LINK_URL + `?email=${email}`,
    })
      .then(({ data }) => {
        toast.success(data.message)
        navigate(SIGN_IN_PAGE)
      })
      .catch(({ response }) => {
        toast.error(
          <div>
            <p>{response?.data.message}</p>
            <p>{response?.data.error}</p>
          </div>
        )
      })
  }

  return (
    <Container className={styles.container}>
      <form className={styles.box} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={'email'}
          control={control}
          render={({ field }) =>
            <TextField
              {...field}
              className={styles.field}
              variant={'standard'}
              label={'email'}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          }
        />

        <FormHelperText>
          На вашу почту будет отправлено письмо с ссылкой для смены пароля
        </FormHelperText>

        <LoadingButton
          loading={mutation.isLoading}
          className={styles.load__btn}
          variant="outlined"
          type={'submit'}
        >
          Подтвердить
        </LoadingButton>
      </form>
    </Container>
  );
};

export default ForgotPassword;