export const confirmTextHelper = (title: string, body: string) => {
  let defaultTitle = 'Удаление'
  let defaultBody = 'Вы уверены что хотите удалить'

  if (title) {
    defaultTitle += ` ${title}.`
  }

  if (body) {
    defaultBody += ` ${body}?`
  }

  return {
    title: defaultTitle,
    body: defaultBody,
    confirm: 'Удалить',
    cancel: 'Отмена'
  }
}