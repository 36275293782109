import React, { FC, useState } from 'react';
import { IMySpeedDialProps } from "./interfaces/interfaces";
import styles from './styles/mySpeedDialStyles.module.scss';
import Icon from "../Icons/Icon";

const MySpeedDial: FC<IMySpeedDialProps> = ({ children }) => {
  const [open, setOpen] = useState(false)

  const onOpenClose = (value: boolean) => setOpen(value)

  return (
    <div className={styles.my_speed_dial__wrapper}>
      <div
        className={open ? styles.my_speed_dial__open_content_wrapper : styles.my_speed_dial__close_content_wrapper}
        onMouseLeave={() => onOpenClose(false)}
      >
        <div className={styles.my_speed_dial__children_wrapper}>
          {open && children}
        </div>
      </div>

      <div
        className={styles.my_speed_dial__open_button}
        onMouseEnter={() => onOpenClose(true)}
      >
        <Icon
          icon={"add"}
          colorClassProp={styles.my_speed_dial__icon_color}
        />
      </div>
    </div>

  );
};

export default MySpeedDial;