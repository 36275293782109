import React from 'react';
import { IMask, IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string, value: string } }) => void
  name: string;
  value: string
  className: string
}

const CustomInputDate = React.forwardRef<HTMLElement, CustomProps>(
  function (props, ref) {
    const { onChange, value, className } = props;
    const blocksDate = {
      d1: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 3
      },
      d2: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 9
      },
      m: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 12
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1000,
        to: 9999
      }
    }

    return (
      <IMaskInput
        {...props}
        className={className}
        // @ts-ignore
        onInput={onChange}
        mask={Date}
        pattern={'d1d2{.}`m{.}`Y'}
        blocks={blocksDate}
        value={value}
        // @ts-ignore
        inputRef={ref}
      />
    );
  },
);

export default CustomInputDate;