import React, { FC, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Icon from "../../../../../../components/UI/Icons/Icon";
import Loader from "../../../../../../components/UI/Loader/Loader";
import useEditUserPermission from "./useEditUserPermission";
import EditProjectPermissions from "./projectPermissions/EditProjectPermissions";

interface Props {
  params: any
}

const EditUserPermissionDialog: FC<Props> = ({ params }) => {
  const {
    isOpen,
    projects,
    handleCloseOpen,
    getUserPermission
  } = useEditUserPermission(params)

  useEffect(() => {
    getUserPermission()
  }, [])

  return (
    <>
      <IconButton onClick={handleCloseOpen}>
        <Icon icon={'editPermissions'} color={'#1d9a00'}/>
      </IconButton>

      <Dialog onClose={handleCloseOpen} open={isOpen} maxWidth={'md'}>
        {!projects ?
          <Loader/>
          :
          <>
            <DialogTitle>
              Редактирование прав пользователю {params.row.email}
            </DialogTitle>

            <DialogContent>
              <EditProjectPermissions params={params} closeDialog={handleCloseOpen}/>
            </DialogContent>
          </>
        }
      </Dialog>
    </>
  )
}

export default EditUserPermissionDialog;