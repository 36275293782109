import React from 'react';
import { Outlet } from 'react-router-dom';
import ProxyHeaders from '../headers/ProxyHeaders';
import styles from './layout.module.scss';
import { useRecoilValue } from 'recoil';
import { userAccessSelector } from '../../store/userStore';

const Layout = () => {
  const { isAuth } = useRecoilValue(userAccessSelector)

  return (
    <div className={styles.wrapper}>
      <ProxyHeaders type={isAuth ? 'auth' : 'main'}/>
      <Outlet/>
    </div>
  );
};

export default Layout;