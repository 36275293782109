import React, { FC, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useQuery } from "react-query";
import styles from "../../users.module.scss";
import { getMunicipalities } from "../../../../../api/municipality/getMunicipalities";
import Loader from "../../../../../components/UI/Loader/Loader";
import Icon from "../../../../../components/UI/Icons/Icon";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface Props {
  params: any
  onSubmit: (userId: number, dto: any) => void
}

const EditUserMunicipality: FC<Props> = ({ params, onSubmit }) => {
  const [open, setOpen] = useState(false)
  const { data, isLoading } = useQuery(['muni'], getMunicipalities)
  const { row } = params

  const {
    handleSubmit,
    register
  } = useForm({
    defaultValues: {
      municipality: row.municipality === 'Не назначен' ? '' : row.municipality
    }
  })

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onHandleSubmit = (dto: any) => {
    if (!dto) {
      onSubmit(row.id, { municipalityId: dto })
      onClose()
      return
    }

    const muni = data!.data.municipalities.filter((muni: any) => muni.name === dto.municipality)[0]

    if (muni) {
      onSubmit(row.id, { municipalityId: dto ? muni.id : dto })
      onClose()
    } else {
      toast.error('Что-то пошло не так, не могу найти округ с таким именем')
    }
  }

  const onRemove = () => onHandleSubmit(null)

  if (isLoading || !data) {
    return <Loader/>
  }

  return (
    <>
      <IconButton onClick={onOpen}>
        <Icon icon={'editMunicipality'}/>
      </IconButton>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {
            row.municipality === 'Не назначен' ?
              'Редактирование округа пользователя' :
              `Округ пользователя: ${row.municipality}`
          }
        </DialogTitle>

        <DialogContent className={styles.muni_dialog_content}>
          <Autocomplete
            className={styles.muni_dialog__select}
            getOptionLabel={(option: any) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={data.data.municipalities.map((muni: any) => ({
              name: muni.name,
            }))}
            renderInput={
              (params) =>
                <TextField
                  {...register('municipality')}
                  {...params}
                  label={'Округ'}
                />
            }
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>
            Отменить
          </Button>

          <Button onClick={onRemove}>
            Убрать ограничение
          </Button>

          <Button onClick={handleSubmit(onHandleSubmit)}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUserMunicipality;