import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomMutation } from "../../api/useCustomMutation";
import { CHECK_RESET_PASSWORD_LINK_URL, RESET_PASSWORD_URL } from "../../api/urls";
import { toast } from "react-toastify";
import { SIGN_IN_PAGE } from "../../router/routeConsts";
import { Card, CardContent, CardHeader } from "@mui/material";
import styles from './resetPassword.module.scss';
import { Controller, useForm } from "react-hook-form";
import PasswordInput from "../../components/UI/Inputs/PasswordInput/PasswordInput";
import LoadingButton from "@mui/lab/LoadingButton";

interface Form {
  newPassword: string
  confirmPassword: string
}

const ResetPassword = () => {
  const { pathname } = useLocation()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const { control, handleSubmit, formState: { errors } } = useForm<Form>()

  const link = pathname.split('password/')[1]

  const onSubmit = ({ newPassword, confirmPassword }: Form) => {
    if (newPassword !== confirmPassword) {
      toast.error('Подтверждждение пароля и пароль не совпадают')
      return
    }

    mutation.mutateAsync({
      method: 'post',
      url: RESET_PASSWORD_URL,
      data: {
        link,
        newPassword,
        confirmPassword
      }
    })
      .then(({ data }) => {
        toast.success(data.message)
        navigate(SIGN_IN_PAGE)
      })
      .catch(({ response }) => {
        toast.error(
          <div>
            <p>{response?.data.message}</p>
            <p>{response?.data.error}</p>
          </div>
        )
        navigate(SIGN_IN_PAGE)
      })
  }

  useEffect(() => {
    mutation.mutateAsync({
      method: 'get',
      url: CHECK_RESET_PASSWORD_LINK_URL + `?link=${link}`,
    })
      .catch(({ response }) => {
        toast.error(
          <div>
            <p>{response?.data.message}</p>
            <p>{response?.data.error}</p>
          </div>
        )
        navigate(SIGN_IN_PAGE)
      })
  }, [])

  return (
    <div className={styles.wrapper}>
      <Card>
        <CardHeader title={'Придумайте новый пароль'}/>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Controller
              name={'newPassword'}
              control={control}
              render={({ field }) =>
                <PasswordInput
                  label={'Новый пароль'}
                  classname={styles.field}
                  input={{
                    ...field,
                    error: !!errors.newPassword,
                    helpertext: errors.newPassword?.message
                  }}
                />
              }
            />
            <Controller
              name={'confirmPassword'}
              control={control}
              render={({ field }) =>
                <PasswordInput
                  label={'Подтвердите пароль'}
                  classname={styles.field}
                  input={{
                    ...field,
                    error: !!errors.confirmPassword,
                    helpertext: errors.confirmPassword?.message
                  }}
                />
              }
            />
            <LoadingButton
              loading={mutation.isLoading}
              variant="outlined"
              type={'submit'}
            >
              Подтвердить
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPassword;