// packages
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { GridColumns } from '@mui/x-data-grid'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
// components
import UserActions from './userActions/UserActions'
import Icon from "../../../components/UI/Icons/Icon"
import ActivateUserAction from "./userActions/actions/ActivateUserAction"
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation'
// logic
import { getDeletedUsers, getUsers } from '../../../api/user/getUsers'
// helpers
import { showDate } from '../../../helpers/showDate'
// types
import { IUser } from '../../../models/IUser'
// urls
import {
  ACTIVATE_USER_URL,
  DEACTIVATE_USER_URL,
  DELETE_USER_URL,
  RESTORE_USER_URL,
  UPDATE_USER_ROLE_URL,
  UPDATE_USER_URL
} from '../../../api/urls'
//other
import styles from './users.module.scss';
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";
import { useGetRole } from "../../../helpers/permissions/useGetRole";

export const useUsers = () => {
  const { role } = useGetRole()
  const mutation = useCustomMutation()
  const deleted = useQuery(['deleted'], () => getDeletedUsers(role), { cacheTime: 120000, staleTime: 120000 })
  const notDeleted = useQuery(['users'], () => getUsers(), { cacheTime: 120000, staleTime: 120000 })

  const [deletedToggle, setDeletedToggle] = useState(false)
  let dataObj: any
  const notDeletedLabel = 'Не удалён'

  if (!deletedToggle) {
    dataObj = notDeleted
  } else {
    dataObj = deleted
  }

  const { refetch: usersRefetch } = notDeleted
  const { refetch: deletedUsersRefetch } = deleted

  const { data: users } = dataObj

  const successMutation = (message: string) => {
    toast.success(message)
    usersRefetch()
    deletedUsersRefetch()
  }

  const handleToggle = () => {
    setDeletedToggle(!deletedToggle)
  }

  const showInfo = () => {
    toast((
      <div className={styles.info_wrapper}>
        <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
          <div className={styles.info__item}>
            <Icon icon={"editMunicipality"}/> - задать/изменить муниципалитет пользователю
          </div>

          <div className={styles.info__item}>
            <Icon icon={"delete"}/> - удалить пользователя
          </div>

          <div className={styles.info__item}>
            <Icon icon={"updateRole"}/> - редатировать роль пользователя
          </div>

          <div className={styles.info__item}>
            <Icon icon={"restore"}/> - восстановить пользователя
          </div>
        </PermissionsGate>

        <div className={styles.info__item}>
          <Icon icon={"ban"}/> - деактивировать пользователя
        </div>

        <div className={styles.info__item}>
          <Icon icon={"activate"}/> - активировать пользователя
        </div>

        <div className={styles.info__item}>
          <Icon icon={"edit"}/> - редатировать данные пользователя
        </div>

        <div className={styles.info__item}>
          <Icon icon={"editPermissions"}/> - назначить проекты пользователю
        </div>
      </div>
    ), { autoClose: 30000 })
  }

  const onEdit = (userId: number, dto: any) => {
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_USER_URL + userId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onRemove = (userId: number) => {
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_USER_URL + userId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onEditRole = (dto: any) => {
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_USER_ROLE_URL
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onRestore = (id: number) => {
    mutation.mutateAsync({
      method: 'patch',
      url: RESTORE_USER_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const activateUser = (userId: number) => {
    mutation.mutateAsync({
      method: 'get',
      url: ACTIVATE_USER_URL + userId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        toast.error(response?.data?.error)
      })
  }

  const banUser = (userId: number) => {
    mutation.mutateAsync({
      method: 'get',
      url: DEACTIVATE_USER_URL + userId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        toast.error(response?.data?.error)
      })
  }

  const rows = users?.data.map((user: IUser) => ({
    id: user.id,
    email: user.email,
    lastname: user.lastname,
    firstname: user.firstname,
    patronymic: user.patronymic,
    role: user.roles[0].name,
    roleId: user.roles[0].id,
    dob: showDate(user.dob),
    municipality: user.municipality?.name || 'Не назначен',
    municipalityId: user.municipalityId,
    dateDeleted: user.dateDeleted ? showDate(user.dateDeleted, { withTime: true }) : notDeletedLabel,
    dateCreated: showDate(user.dateCreated, { withTime: true }),
    activationDate: user.activationDate,
    actions: user.id,
  }))

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 150,
      type: 'string'
    },
    {
      field: 'lastname',
      headerName: 'Фамилия',
      width: 150,
      type: 'string'
    },
    {
      field: 'firstname',
      headerName: 'Имя',
      width: 150,
      type: 'string'
    },
    {
      field: 'patronymic',
      headerName: 'Отчество',
      width: 150,
      type: 'string'
    },
    {
      field: 'role',
      headerName: 'Роль',
      width: 120,
      type: 'string'
    },
    {
      field: 'municipality',
      headerName: 'Округ',
      width: 150,
      type: 'string'
    },
    {
      field: 'dob',
      headerName: 'Дата рождения',
      width: 100,
      type: 'date',
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 150,
      type: 'date',
    },
    {
      field: 'activationDate',
      headerName: 'Дата активации',
      width: 150,
      renderCell: (params =>
          <ActivateUserAction
            params={params}
            activate={activateUser}
            deactivate={banUser}
          />
      )
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 350,
      renderCell: (params =>
          <UserActions
            onEdit={onEdit}
            onRemove={onRemove}
            params={params}
            onEditRole={onEditRole}
            onRestore={onRestore}
          />
      )
    }
  ]

  return {
    rows,
    columns,
    handleToggle,
    isExistDeleted: !deleted?.data?.data?.length && !deletedToggle,
    showInfo
  }
}