import React, { FC, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { Button, Stack } from "@mui/material";
import styles from './styles.module.scss';

interface Props {
  stringForConfirm: string
  okButtonText: string
  cancelButtonText: string
  title: string
  body: string | JSX.Element
}

const CustomConfirmDeleteDialog: FC<Props> = ({ stringForConfirm, okButtonText, cancelButtonText, title, body }) => {
  const dialog = useDialog();
  const [value, setValue] = useState<string>('');

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>

      <div className={styles.body}>
        <div>{body}</div>

        <div className={styles.animated__warning}>
          <h1>Восстановить эти данные будет невозможно!</h1>
        </div>

        <input
          type="text"
          onChange={(e) => setValue(e.target.value)}
        />

        <Stack direction={'row'} spacing={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close(true)
            }}
            disabled={stringForConfirm !== value.trim()}
          >
            {okButtonText}
          </Button>

          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close(false)
            }}
          >
            {cancelButtonText}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default CustomConfirmDeleteDialog;