// packages
import React, { FC, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import { showDate } from "../../../../../helpers/showDate";
import Icon from "../../../../../components/UI/Icons/Icon";

interface Props {
  params: GridRenderCellParams,
  activate: (userId: number) => void,
  deactivate: (userId: number) => void,
}

const ActivateUserAction: FC<Props> = ({ params, activate, deactivate }) => {
  const [openActivationDialog, setOpenActivationDialog] = useState(false)
  const [openBanDialog, setOpenBanDialog] = useState(false)
  const notDelete = 'Не удалён'

  const onOpenAlert = () => setOpenActivationDialog(true)
  const handleCloseAlert = () => setOpenActivationDialog(false)
  const onOpenBanDialog = () => setOpenBanDialog(true)
  const handleCloseBanDialog = () => setOpenBanDialog(false)

  const onAgree = () => {
    activate(params.row.id)
    handleCloseAlert()
  }

  const onBan = () => {
    deactivate(params.row.id)
    handleCloseBanDialog()
  }

  if (params.row.activationDate) {
    return (
      <>
        <p>
          {showDate(params.row.activationDate, { withTime: true })}
        </p>

        <IconButton onClick={onOpenBanDialog} disabled={params.row.dateDeleted !== notDelete}>
          <Icon icon={'ban'} color={'#ff5b00'}/>
        </IconButton>

        <Dialog open={openBanDialog} onClose={handleCloseBanDialog}>
          <DialogTitle>
            Деактивация аккаунта {params.row.email}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Пользователь {params.row.lastname} {params.row.firstname} {params.row.patronymic} активирован. Вы
              уверены, что хотите деактивировать его аккаунт?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseBanDialog} autoFocus>
              Отмена
            </Button>
            <Button onClick={onBan}>
              Деактивировать
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  } else {
    return (
      <>
        <IconButton onClick={onOpenAlert} disabled={params.row.dateDeleted !== notDelete}>
          <Icon icon={'activate'} color={'#ff5b00'}/>
        </IconButton>

        <Dialog open={openActivationDialog} onClose={handleCloseAlert}>
          <DialogTitle>
            Активация пользователя {params.row.email}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Пользователь {params.row.firstname} {params.row.lastname}
              {params.row.patronymic} ещё не активирован. Вы
              уверены, что хотите активировать его аккаунт?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAlert} autoFocus>
              Отмена
            </Button>
            <Button onClick={onAgree}>
              Активировать
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ActivateUserAction