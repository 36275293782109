import { SubmitHandler, useForm } from 'react-hook-form';
import { ISignUp } from '../../../models/Types';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSignUpSchema } from '../../../validationSchemas/userSignSchemas';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { SIGN_UP_URL } from '../../../api/urls';
import { toast } from 'react-toastify';
import { validateDatesInObject } from "../../../helpers/validateDatesInObject";

export const useSignUp = (onClose: () => void) => {
  const mutation = useCustomMutation()

  const form = useForm<ISignUp>({
    mode: 'all',
    resolver: yupResolver(userSignUpSchema),
    defaultValues: {
      dob: '',
      password: '',
      patronymic: '',
      lastname: '',
      firstname: '',
      email: ''
    }
  })

  const onRegister: SubmitHandler<ISignUp> = async (data) => {
    try {
      validateDatesInObject(data, ['dob'])
      await mutation.mutateAsync({ method: 'post', url: SIGN_UP_URL, data })
      toast.success('Пользователь успешно зарегестрирован')
      onClose()
    } catch (e: any) {
      toast.error(e.response?.data?.message)
    }
  }

  return {
    isLoading: mutation.isLoading,
    onRegister,
    form
  }
}