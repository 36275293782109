// packages
import React, { FC, memo } from 'react'
import { Stack } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'
// components
import DeleteOrRestoreButtons from '../../../../components/UI/CrudActions/DeleteOrRestoreButtons'
import UpdateRoleDialog from "./actions/UpdateRoleDialog";
import EditUserDialog from "./actions/EditUserDialog";
import EditUserMunicipality from "./actions/EditUserMunicipality";
import EditUserPermissionDialog from "./actions/editUserPermission/EditUserPermissionDialog";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";

interface Props {
  params: GridRenderCellParams
  onEdit: (userId: number, dto: any) => void
  onRemove: (userId: number) => void
  onRestore: (userId: number) => void
  onEditRole: (dto: any) => void
}

const UserActions: FC<Props> = ({ params, onEdit, onRemove, onEditRole, onRestore }) => {

  return (
    <Stack direction={'row'} spacing={1}>
      <EditUserDialog onEdit={onEdit} params={params}/>

      <PermissionsGate scopes={[SCOPES.admin]}>
        <EditUserMunicipality params={params} onSubmit={onEdit}/>
      </PermissionsGate>

      <EditUserPermissionDialog params={params}/>

      <PermissionsGate scopes={[SCOPES.admin]}>
        <UpdateRoleDialog onEditRole={onEditRole} params={params}/>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.admin]}>
        <DeleteOrRestoreButtons
          isLoading={false}
          onRestore={onRestore}
          params={params}
          onRemove={onRemove}
          currentObject={'user'}
        />
      </PermissionsGate>
    </Stack>
  )
}

export default memo(UserActions)