// packages
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation'
// urls
import { CREATE_MUNICIPALITY_URL } from '../../../api/urls'

interface Props {
  isOpen: boolean,
  onClose: any,
  refetch: any
}

const CreateMunicipalityForm: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const mutation = useCustomMutation()
  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: '',
    }
  })

  const onCancel = () => {
    reset()
    onClose()
  }

  const onCreateProject = (dto: any) => {
    setIsLoading(true)

    mutation.mutateAsync({
      method: 'post',
      data: dto,
      url: CREATE_MUNICIPALITY_URL
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        onClose(false)
        reset()
        refetch()
        setIsLoading(false)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          Создание округа
        </DialogTitle>

        <DialogContent>
          <TextField {...register('name')} label={'Название'}/>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={onCancel}
            autoFocus
            loading={isLoading}
          >
            Отмена
          </LoadingButton>

          <LoadingButton
            onClick={handleSubmit(onCreateProject)}
            loading={isLoading}
          >
            Cоздать
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateMunicipalityForm