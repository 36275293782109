import React, { FC } from 'react';
import styles from './infoButton.module.scss';
import Icon from "../Icons/Icon";

interface Props {
  onClick: () => void
}

const InfoButton: FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.info__button} onClick={onClick}>
      <Icon icon={"info"}/>
    </div>
  );
};

export default InfoButton;