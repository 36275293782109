import { FixedLengthArray } from '../models/IFixedLengthArray';

export const wordEnding = (number: number, words: FixedLengthArray<string, 3>) => {
  let val = number % 100
  if (val > 10 && val < 20) return `${number} ${words[2]}`
  else {
    val = number % 10
    if (val === 1) return `${number} ${words[0]}`
    else if (val > 1 && val < 5) return `${number} ${words[1]}`
    else return `${number} ${words[2]}`
  }
}