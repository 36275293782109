import React from 'react';
import styles from '../profile.module.scss';
import { useRecoilValue } from 'recoil';
import { userSelector } from '../../../store/userStore';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { UPDATE_USER_URL } from '../../../api/urls';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const EditProfilePage = () => {
  const user = useRecoilValue(userSelector)
  const mutation = useCustomMutation()

  const { handleSubmit, register } = useForm({
    defaultValues: {
      lastname: user.lastname,
      firstname: user.firstname,
      email: user.email,
      dob: user.dob,
    }
  })

  const onSubmit = (dto: any) => {
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_USER_URL + user.id
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }
  return (
    <form>
      <div className={styles.container}>

        <div className={styles.edit_profile_wrapper}>

          <TextField {...register('lastname')} label={'Фамилия'}/>
          <TextField {...register('firstname')} label={'Имя'}/>
          <TextField {...register('email')} label={'E-mail'}/>

          <Button onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </div>

      </div>
    </form>
  );
};

export default EditProfilePage;