import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './navbar.module.scss';
import cn from 'classnames'
import { INavbarProps } from '../../../models/INavbarProps';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';

const Navbar: FC<INavbarProps> = ({ routes, label }) => {
  const location = useLocation()

  const activeLinkClassname = cn(styles.link, styles.active__link)

  return (
    <Box className={styles.nv__wrapper}>
      <Typography variant={'h5'}>{label}</Typography>
      {routes.map(route =>
        <PermissionsGate key={route.label} scopes={route.scopes}>
          <NavLink
            className={location.pathname === route.to ? activeLinkClassname : styles.link}
            to={route.to}
            key={route.label}
          >
            {route.label}
          </NavLink>
        </PermissionsGate>
      )}
    </Box>
  );
};

export default Navbar;