import { Navigate, useLocation } from 'react-router-dom';
import { FC, Suspense } from 'react';
import { SIGN_IN_PAGE } from '../router/routeConsts';
import { useRecoilValue } from 'recoil'
import { userAccessState } from '../store/userStore';
import Loader from '../components/UI/Loader/Loader';

interface RequireAuthProps {
  children: JSX.Element
}

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const { isAuth } = useRecoilValue(userAccessState)
  const location = useLocation()

  if (!isAuth) {
    return <Navigate replace state={{ from: location }} to={SIGN_IN_PAGE}/>
  }

  return (
    <Suspense fallback={<Loader/>}>
      {children}
    </Suspense>
  )
}