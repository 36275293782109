const RADIUS = 6378137 // meters

export const getDistanceBetweenTwoPoints = (firstPoint: number[], secondPoint: number[]) => {
  const radian = Math.PI / 180

  const phi1 = firstPoint[1] * radian
  const phi2 = secondPoint[1] * radian

  const deltaPhi = (secondPoint[1] - firstPoint[1]) * radian
  const deltaLambda = (secondPoint[0] - firstPoint[0]) * radian

  const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return RADIUS * c
}

const rad = (coordinate: number) => (coordinate * Math.PI) / 180;

const ringArea = (points: number[][]) => {
  let p1, p2, p3
  let lowerIndex, middleIndex, upperIndex
  let area = 0

  if (points.length > 2) {
    for (let i = 0; i < points.length; i++) {
      const x = 0
      const y = 1

      if (i === points.length - 2) {
        lowerIndex = points.length - 2
        middleIndex = points.length - 1
        upperIndex = 0
      } else if (i === points.length - 1) {
        lowerIndex = points.length - 1
        middleIndex = 0
        upperIndex = 1
      } else {
        lowerIndex = i
        middleIndex = i + 1
        upperIndex = i + 2
      }

      p1 = points[lowerIndex]
      p2 = points[middleIndex]
      p3 = points[upperIndex]

      area += (rad(p3[x]) - rad(p1[x])) * Math.sin(rad(p2[y]))
    }

    area = (area * RADIUS * RADIUS) / 2
  }

  return area;
};

export const getArea = (points: number[][]) => {
  let area = 0

  if (points && points.length > 0) {
    area += Math.abs(ringArea(points))
  }

  return area;
}