import React, { FC } from 'react';
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import styles from "./mapLayoutModal.module.scss";
import Icon from "../../../../components/UI/Icons/Icon";
import Loader from "../../../../components/UI/Loader/Loader";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import { Button } from "@mui/material";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import { DELETE_BORDER_URL, DELETE_SECTION_URL } from "../../../../api/urls";
import { ls_getProjectInfo } from "../../../../helpers/localStorage";
import { toast } from "react-toastify";

interface Props {
  onClose: () => void,
}

const SectionModal: FC<Props> = ({ onClose }) => {
  const [mapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const project = ls_getProjectInfo()

  const onDelete = async () => {
    try {
      if (!mapState.selectedObject || !mapState.preSelectedObject) {
        toast.error('Something wet wrong. There is no selected or preselected object')
        return
      }

      await mutation.mutateAsync({
        method: 'delete',
        url:
          (mapState.preSelectedObject.type === 'section' ? DELETE_SECTION_URL : DELETE_BORDER_URL) +
          mapState.selectedObject.id +
          `?schema=${project.schema}`
      })

      toast.success('Объект удалён, презагрузите страницу, чтобы увидеть изменения')
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  if (mapState.preSelectedObject && (mapState.preSelectedObject?.type === 'section' || mapState.preSelectedObject?.type === 'border')) {
    return <PermissionsGate scopes={[SCOPES.superAdmin]}>
      <div className={styles.modal}>
        <div className={styles.close} onClick={onClose}>
          <Icon icon={"close"}/>
        </div>

        <p>{mapState.preSelectedObject?.type === 'border' ? 'Граница' : 'Сектор'}</p>

        {mapState.selectedObject ?
          <div className={styles.section_and_border_modal_container}>
            <div>
              <p> id: {mapState.selectedObject.id}</p>
              {mapState.selectedObject.sectionNumber ? <p>Номер: {mapState.selectedObject.sectionNumber}</p> : ''}
            </div>

            <Button
              className={styles.section_and_border_modal_delete_btn}
              onClick={onDelete}
              variant={"outlined"}
              color={'error'}
            >
              Удалить
            </Button>
          </div>
          :
          <Loader/>
        }
      </div>
    </PermissionsGate>
  }

  return <></>;
};

export default SectionModal;