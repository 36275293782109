import React from 'react';
import { Container, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordInput from '../../../components/UI/Inputs/PasswordInput/PasswordInput';
import styles from '../sign.module.scss';
import { Controller } from 'react-hook-form';
import { useSignIn } from './useSignIn';
import { FORGOT_PASSWORD_PAGE } from "../../../router/routeConsts";
import { Link } from "react-router-dom";

const SignIn = () => {
  const {
    isLoading,
    onLogin,
    form: {
      handleSubmit,
      control,
      formState: { errors }
    }
  } = useSignIn()

  return (
    <Container className={styles.container}>
      <form className={styles.box} onSubmit={handleSubmit(onLogin)}>
        <Controller
          name={'email'}
          control={control}
          render={({ field }) =>
            <TextField
              {...field}
              className={styles.field}
              variant={'standard'}
              label={'email'}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          }
        />

        <Controller
          name={'password'}
          control={control}
          render={({ field }) =>
            <PasswordInput
              classname={styles.field}
              input={{
                ...field,
                error: !!errors.password,
                helpertext: errors.password?.message
              }}
            />
          }
        />

        <div className={styles.link_wrapper}>
          <Link to={FORGOT_PASSWORD_PAGE} className={styles.link}>
            <Typography>Забыли пароль?</Typography>
          </Link>
        </div>

        <LoadingButton
          loading={isLoading}
          className={styles.load__btn}
          variant="outlined"
          type={'submit'}
        >
          Войти
        </LoadingButton>
      </form>
    </Container>
  );
};

export default SignIn;