import React, { FC } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

interface Props {
  label?: string
  items: { cb: () => void, text: string }[]
}

const ActionList: FC<Props> = ({ items, label }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={'contained'}
        style={{ margin: '0 .5rem' }}
      >
        {label || 'Действия'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map(item =>
          <MenuItem
            key={item.text}
            onClick={() => {
              item.cb()
              handleCloseMenu()
            }}
          >
            {item.text}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActionList;