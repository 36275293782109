import React, { FC } from 'react';
import AuthenticatedHeader from './AuthentificatedHeader/AuthenticatedHeader';
import MainHeader from './MainHeader/MainHeader';

interface ProxyHeadersProps {
  type: 'auth' | 'main'
}

const ProxyHeaders: FC<ProxyHeadersProps> = ({ type }) => {
  const headersComponents = {
    auth: AuthenticatedHeader,
    main: MainHeader
  }
  const CurrentHeader = headersComponents[type]
  return <CurrentHeader/>
};

export default ProxyHeaders;