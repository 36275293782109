// packages
import React, { useState } from 'react';
import { Button, Dialog, FormControlLabel, IconButton, Stack, Switch, TextField } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import Icon from "../../../components/UI/Icons/Icon";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller } from "react-hook-form";
// components
import Loader from '../../../components/UI/Loader/Loader';
import CustomInputDate from "../../../components/UI/Inputs/CustomInputDate/CustomInputDate";
// logic
import { useUsers } from './useUsers';
import { useSignUp } from "../../authorization/SignUp/useSignUp";
// styles
import styles from './users.module.scss';
//other
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";

const Users = () => {
  const { columns, rows, handleToggle, isExistDeleted, showInfo } = useUsers()
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const {
    isLoading,
    onRegister,
    form: {
      handleSubmit,
      control,
      formState: { errors }
    }
  } = useSignUp(onClose)

  if (!rows || !columns) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1}>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <div style={{ display: "flex" }}>
            <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
              <FormControlLabel
                control={<Switch
                  defaultChecked={false}
                  onChange={handleToggle}
                  disabled={isExistDeleted}
                />}
                label="Удалёные пользователи"
              />
            </PermissionsGate>

            <div>
              <div className={styles.register_user_btn}>
                <Button onClick={onOpen}>
                  Зарегистрировать пользователя
                </Button>
              </div>

              <Dialog open={open} onClose={onClose}>
                <form className={styles.box_sign_up} onSubmit={handleSubmit(onRegister)}>
                  <Controller
                    name={'firstname'}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        label={'Имя'}
                        error={!!errors.firstname}
                        helperText={errors.firstname?.message}
                      />
                    }
                  />

                  <Controller
                    name={'lastname'}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        label={'Фамилия'}
                        error={!!errors.lastname}
                        helperText={errors.lastname?.message}
                      />
                    }
                  />

                  <Controller
                    name={'patronymic'}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        label={'Отчество'}
                        error={!!errors.patronymic}
                        helperText={errors.patronymic?.message}
                      />
                    }
                  />

                  <Controller
                    name={'email'}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        label={'Email'}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    }
                  />

                  <Controller
                    control={control}
                    name={'dob'}
                    render={({ field, formState }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        error={!!formState.errors?.dob}
                        helperText={formState.errors?.dob?.message}
                        label={'Дата рождения'}
                        size={'small'}
                        InputProps={{
                          inputComponent: CustomInputDate as any
                        }}
                      />}
                  />

                  <Controller
                    name={'password'}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        className={styles.field}
                        variant={'standard'}
                        label={'Пароль'}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    }
                  />

                  <LoadingButton
                    className={styles.load__btn}
                    loading={isLoading}
                    variant="outlined"
                    type={'submit'}
                  >
                    Зарегистрировать
                  </LoadingButton>
                </form>
              </Dialog>
            </div>
          </div>

          <IconButton onClick={showInfo}>
            <Icon icon={"info"}/>
          </IconButton>
        </Stack>

        <div className={styles.table_wrapper}>
          <DataGrid
            columns={columns}
            rows={rows}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Stack>
    </div>
  )
}

export default Users