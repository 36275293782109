import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../headers.module.scss';
import logo from '../../../assets/cloudmaps.png';
import { Button } from '@mui/material';
import { SIGN_IN_PAGE } from '../../../router/routeConsts';
import { useHeaders } from '../useHeaders';

const MainHeader = () => {
  const { toHome } = useHeaders()

  return (
    <div className={styles.mh__wrapper}>
      <div className={styles.mh__logo__wrapper}>
        <img src={logo} alt="logo" onClick={toHome}/>
      </div>
      <div className={styles.mh__buttons}>
        <Button><Link className={styles.link} to={SIGN_IN_PAGE}>Войти</Link></Button>
      </div>
    </div>
  );
};

export default MainHeader;