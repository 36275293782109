import React, { forwardRef } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { TableComponents } from 'react-virtuoso';

export const useTable = () => {

  const configMUITable: TableComponents = {
    Scroller: forwardRef((props, ref) =>
      <TableContainer component={Paper} {...props} ref={ref}/>),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }}/>,
    // @ts-ignore
    TableHead: TableHead,
    TableRow: TableRow,
    TableBody: forwardRef((props, ref) =>
      // @ts-ignore
      <TableBody {...props} ref={ref}/>),
  }

  return {
    configMUITable
  }
}