import { axiosInstance } from '../axios';
import { GET_ALL_DELETED_USERS_URL, GET_ALL_USERS_URL, GET_USERS_ROLE_URL } from '../urls';
import { IUser } from '../../models/IUser';
import { PERMISSIONS, SCOPES } from "../../helpers/permissions/permissionsMaps";
import { hasPermissions } from "../../helpers/permissions/PermissionsGate";
import { AxiosResponse } from "axios";

export const getUsers = (url = GET_ALL_USERS_URL) => {
  return axiosInstance.get<IUser[]>(url);
}

export const getDeletedUsers = (role = 'admin') => {
  const permissions = PERMISSIONS[role]

  const permissionGranted = hasPermissions({ permissions, scopes: [SCOPES.admin, SCOPES.superAdmin] })

  if (permissionGranted) {
    return axiosInstance.get<IUser[]>(GET_ALL_DELETED_USERS_URL);
  }

  return {} as AxiosResponse<IUser[]>
}

export const getRoles = () => {
  return axiosInstance.get(GET_USERS_ROLE_URL);
}