import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL, REFRESH_URL } from './urls';
import { IRefresh } from '../models/IRefresh';
import { ACCESS_TOKEN_KEY, ls_setTokens, REFRESH_TOKEN_KEY } from '../helpers/localStorage';

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  if (token && config.headers) {
    config.headers['authorization'] = 'Bearer ' + token
  }
  return config
})

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error?.response?.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || ''

        const { data, status } = await axiosInstance
          .get<IRefresh>(REFRESH_URL, { headers: { 'refresh_token': refreshToken } })

        if (status === 403) {
          localStorage.clear()
          window.location.reload()
          return
        }

        ls_setTokens({
          refresh_token: data.refresh_token,
          access_token: data.access_token
        })

        return axiosInstance(originalRequest)
      } catch (e) {
        localStorage.clear()
        window.location.reload()
      }
    }
    throw error
  })
