import React, { useState } from 'react';
import { Card, CardContent, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { projectsState } from '../../../../store/projectStore';
import { useQuery } from 'react-query';
import { getProjectWithoutSchema } from '../../../../api/project/projectApi';
import styles from './mainStatistic.module.scss';
import { IProject } from '../../../../models/IProject';
import { showDate } from '../../../../helpers/showDate';
import Loader from '../../../../components/UI/Loader/Loader';
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";

const MainStatistic = () => {
  const [projects] = useRecoilState(projectsState)
  const { data } = useQuery(['projects-without-data'], getProjectWithoutSchema)
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => setIsOpen(!isOpen)

  if (!data) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Dialog open={isOpen} onClose={onToggle}>
          <DialogTitle>Пустые проекты (нет схемы в БД)</DialogTitle>

          <DialogContent>
            <Stack spacing={1}>
              {data.projects.map((project: IProject) =>
                <Stack key={project.schema} className={styles.project_info} direction={'row'} spacing={1}>
                  <Typography variant={'h6'}>{project.name}</Typography>
                  <Typography variant={'h6'}>{project.schema}</Typography>
                  <Typography variant={'h6'}>{showDate(project.dateCreated, { withTime: true })}</Typography>
                </Stack>
              )}
            </Stack>
          </DialogContent>
        </Dialog>

        <Card>
          <CardContent>
            <Typography variant={'h6'}>Проекты</Typography>

            <Stack direction={'row'} spacing={2} mt={1}>
              <Typography>Всего: {projects.projects.length}</Typography>
              <Typography className={styles.projects_without_data} onClick={onToggle}>
                Без данных (нет схемы): {data.projects.length}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </PermissionsGate>
    </div>
  );
};

export default MainStatistic;