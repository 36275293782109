import { atom, selector } from 'recoil';
import { IUser } from '../models/IUser';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../helpers/localStorage';
import { axiosInstance } from '../api/axios';
import { GET_USER_URL } from '../api/urls';
import { IUserAccess } from '../models/IUserAccess';

export const userState = atom({
  key: 'user-state',
  default: {} as IUser,
})

export const userAccessState = atom({
  key: 'user-access-state',
  default: {
    access_token: localStorage.getItem(ACCESS_TOKEN_KEY),
    refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
    isAuth: !!localStorage.getItem(ACCESS_TOKEN_KEY),
  } as IUserAccess
})

export const userSelector = selector({
  key: 'user-selector',
  get: ({ get }) => {
    const user = get(userState)
    const userPermissions = get(userAccessState)

    return {
      ...user,
      ...userPermissions,
    }
  }
})

export const getUserInfoSelector = selector({
  key: 'get-user-info-selector',
  get: async ({ get }) => {
    try {
      const { data } = await axiosInstance.get<IUser>(GET_USER_URL)

      return data
    } catch (e: any) {
      if (e?.response?.data?.message) {
        throw e.response.data.message
      }
      throw e.message
    }
  }
})

export const userAccessSelector = selector({
  key: 'user-access-selector',
  get: ({ get }) => get(userAccessState)
})