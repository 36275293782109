import dayjs from 'dayjs';
import { NO_INFO } from '../utils/utils';

interface ShowDateOptions {
  withTime?: boolean
}

type ShowDateData = Date | null | string | undefined

const defaultOptions: ShowDateOptions = {
  withTime: false
}

export const showDate = (date: ShowDateData, { withTime }: ShowDateOptions = defaultOptions) => {
  if (!date) return NO_INFO

  const validateDate = dayjs(date)

  return validateDate.isValid()
    ? validateDate.format(withTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY')
    : NO_INFO
}
