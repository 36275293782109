import { ISignIn } from '../../../models/Types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSignInSchema } from '../../../validationSchemas/userSignSchemas';
import { useNavigate } from 'react-router-dom';
import { PROJECTS_PAGE } from '../../../router/routeConsts';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { GET_USER_URL, SIGN_IN_URL } from '../../../api/urls';
import { useRecoilState } from 'recoil'
import { userAccessState, userState } from '../../../store/userStore';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ls_setTokens } from '../../../helpers/localStorage';
import { ITokens } from '../../../models/ITokens';
import { IUser } from '../../../models/IUser';

export const useSignIn = () => {
  const navigate = useNavigate()
  const mutation = useCustomMutation()
  const [, setUserAccess] = useRecoilState(userAccessState)
  const [, setUser] = useRecoilState(userState)
  const form = useForm<ISignIn>({
    mode: 'all',
    resolver: yupResolver(userSignInSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onLogin: SubmitHandler<ISignIn> = async (data: any) => {
    try {
      const tokensRes: AxiosResponse<ITokens> = await mutation.mutateAsync({
        method: 'post',
        url: SIGN_IN_URL,
        data
      })

      setUserAccess({
        access_token: tokensRes.data.access_token,
        refresh_token: tokensRes.data.refresh_token,
        isAuth: true
      })
      setUser({ id: tokensRes.data.userId } as IUser)
      ls_setTokens(tokensRes.data)

      const userRes = await mutation.mutateAsync({
        method: 'get',
        url: GET_USER_URL
      })

      setUser(userRes.data)
      navigate(PROJECTS_PAGE)
    } catch (e: any) {
      toast.error(e.response?.data?.message)
    }
  }

  return {
    isLoading: mutation.isLoading,
    onLogin,
    form
  }
}