import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getUserInfoSelector, userState } from '../../store/userStore';
import { useQuery } from 'react-query';
import { getProjects } from '../../api/project/projectApi';
import { projectsState } from '../../store/projectStore';
import Loader from '../../components/UI/Loader/Loader';

const Main = () => {
  const { isLoading, data } = useQuery(['current-project'], getProjects)
  const [, setProjects] = useRecoilState(projectsState)
  const userInfo = useRecoilValue(getUserInfoSelector)
  const [, setUser] = useRecoilState(userState)

  useEffect(() => {
    setUser({ ...userInfo })
    if (data) {
      setProjects({
        ...projectsState,
        projects: data.data
      })
    }
  }, [isLoading])

  if (isLoading || !data) {
    return <Loader/>
  }

  return (
    <div>
      <Outlet/>
    </div>
  )
};

export default Main;