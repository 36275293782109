import React from 'react';
import styles from '../profile.module.scss';
import { useRecoilValue } from "recoil";
import { userSelector } from "../../../store/userStore";
import dayjs from 'dayjs';
import { Card } from "@mui/material";

const MainProfilePage = () => {

  const user = useRecoilValue(userSelector)

  return (
      <Card elevation={4} className={styles.container}>
      <div className={styles.label_wrapper}>
        <p>Фамилия: </p>
        <p>Имя: </p>
        <p>Почта: </p>
        <p>Дата рождения: </p>
        <p>Роль: </p>
        <p>Округ: </p>
      </div>
      <div className={styles.data_container}>
        <p>{user.lastname}</p>
        <p>{user.firstname}</p>
        <p>{user.email}</p>
        <p>{dayjs(user.dob).format('DD.MM.YYYY')}</p>
        <p>{user.roles?.map(role => role.name).join(', ')}</p>
        <p>{user.municipality?.name}</p>
      </div>
  </Card>
  );
};

export default MainProfilePage;

