import { HOME_PAGE } from '../../router/routeConsts';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { userAccessState, userState } from '../../store/userStore';
import { ls_clear } from '../../helpers/localStorage';

export const useHeaders = () => {
  const navigate = useNavigate()
  const resetUserState = useResetRecoilState(userState)
  const resetUserAccessState = useResetRecoilState(userAccessState)

  const toHome = () => {
    navigate(HOME_PAGE)
  }

  const logout = () => {
    window.location.replace(HOME_PAGE)
    resetUserAccessState()
    resetUserState()
    ls_clear()
  }

  return {
    logout,
    toHome
  }
}