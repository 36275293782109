// packages
import React, { useState } from 'react'
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { Button, FormControlLabel, Stack, Switch } from '@mui/material'
// components
import Loader from '../../../components/UI/Loader/Loader';
import CreateProjectForm from './CreateProjectForm'
// logic
import { useProjects } from './useProjects'
// styles
import styles from './projects.module.scss';

const Projects = () => {
  const [showCreate, setShowCreate] = useState(false)

  const onClose = () => setShowCreate(false)

  const onOpen = () => setShowCreate(true)

  const urlRender = (value: string) => {
    return (
      <a href={value}>
        {value}
      </a>
    )
  }

  const { rows, columns, refetch, handleToggle, isExistDeleted } = useProjects({ urlRender })

  if (!rows) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1}>
        <div className={styles.title}>
          <h1>Управление проектами</h1>
          <Button onClick={onOpen} variant={'outlined'}>
            Создать проект
          </Button>
        </div>

        {/*<CreateProjectDialog onClose={onClose} open={showCreate}/>*/}

        <CreateProjectForm refetch={refetch} isOpen={showCreate} onClose={onClose}/>

        <FormControlLabel
          control={
            <Switch
              defaultChecked={false}
              onChange={handleToggle}
              disabled={isExistDeleted}
            />}
          label="Удалёные проекты"
        />

        <div className={styles.table_wrapper}>
          <DataGrid
            columns={columns}
            rows={rows}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Stack>
    </div>
  )
}

export default Projects