import * as yup from 'yup';
import './_locale'

export const userSignInSchema = yup.object().shape({
  email: yup.string().label('Email').required(),
  password: yup.string().label('Password').required().min(4).max(16)
})

export const userSignUpSchema = yup.object().shape({
  email: yup.string().label('Email').required(),
  firstname: yup.string().label('Имя').required().min(3).max(50),
  lastname: yup.string().label('Фамилия').required().min(3).max(50),
  patronymic: yup.string().label('Отчество').required().min(3).max(50),
  password: yup.string().label('Пароль').required().min(4).max(20),
  dob: yup.mixed().label('Дата рождения').required()
})