// packages
import React, { FC, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
// components
import Icon from "../../../../components/UI/Icons/Icon";
import DeleteOrRestoreButtons from "../../../../components/UI/CrudActions/DeleteOrRestoreButtons";

interface Props {
  params: GridRenderCellParams
  onEdit: any
  onRemove: any
  isLoading: boolean
  onRestore: any
}

const MunicipalityActions: FC<Props> = ({ params, onEdit, onRemove, isLoading, onRestore }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: params.row.name,
    }
  })

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)

  const handleCloseEdit = () => setIsOpenEditDialog(false)
  const handleOpenEdit = () => setIsOpenEditDialog(true)

  const onEditProject = (dto: any) => {
    onEdit(params.row.id, dto)
    handleCloseEdit()
    reset()
  }

  return (
    <>
      <IconButton onClick={handleOpenEdit}>
        <Icon icon={'edit'} color={'#ff5b00'}/>
      </IconButton>

      <DeleteOrRestoreButtons
        isLoading={isLoading}
        onRestore={onRestore}
        params={params}
        onRemove={onRemove}
        currentObject={'municipality'}
      />

      <Dialog open={isOpenEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>
          Обновление округа
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('name')} label={'Название'}/>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={handleCloseEdit} autoFocus loading={isLoading}>
            Отмена
          </LoadingButton>
          <LoadingButton onClick={handleSubmit(onEditProject)}>
            Обновить
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MunicipalityActions