import React, { FC, useState } from 'react';
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, InputProps } from '@mui/material';
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel';
import Icon from '../../Icons/Icon';

interface PasswordInputProps {
  classname?: string
  input?: InputProps & { helpertext?: string }
  InputLabelProps?: InputLabelProps
  label?: string
}

const PasswordInput: FC<PasswordInputProps> = ({
                                                 input,
                                                 InputLabelProps,
                                                 classname,
                                                 label = 'password'
                                               }) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  return (
    <FormControl className={classname} variant="standard">
      <InputLabel {...InputLabelProps} htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        {...input}
        id="standard-adornment-password"
        type={isShowPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {isShowPassword ? <Icon icon={'visibilityOff'}/> : <Icon icon={'visibility'}/>}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error>{input?.helpertext}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;