import React from 'react';
import styles from './profile.module.scss';
import { Box } from '@mui/material';
import Navbar from '../../components/UI/Navbar/Navbar';
import {
  EDIT_PROFILE_PAGE,
  PHOTO_STATISTIC_PROFILE_PAGE,
  PROFILE_PAGE,
  STATISTIC_PROFILE_PAGE
} from '../../router/routeConsts';
import { Outlet } from 'react-router-dom';
import { SCOPES } from '../../helpers/permissions/permissionsMaps';

const Profile = () => {

  const routes = [
    { to: PROFILE_PAGE, label: 'Общая информация', scopes: [SCOPES.canView] },
    { to: EDIT_PROFILE_PAGE, label: 'Редактирование профиля', scopes: [SCOPES.canView] },
    { to: STATISTIC_PROFILE_PAGE, label: 'Статистика расшифровки', scopes: [SCOPES.canEdit] },
    { to: PHOTO_STATISTIC_PROFILE_PAGE, label: 'Статистика фотографий', scopes: [SCOPES.canEdit] }
  ]

  return (
    <Box className={styles.wrapper}>
      <Navbar routes={routes} label={''}/>
      <Outlet/>
    </Box>
  );
};

export default Profile;
